<template>
    <div>
        <div align="center">
            <h4 class="q-mb-sm">Rinnovo pratiche in scadenza</h4>

            <br><br>
            <hr>
        </div>

        <div class="text-center justify-center q-pa-md q-gutter-sm fixed-center"
            v-show="!is_loading"
            style="z-index: 1000;">

            <q-spinner-hourglass
                size="4em"
            />
        </div>

        <div class="row justify-center">

            <div class="col-12 col-md-4 q-mr-sm">
                <q-input outlined
                    v-model="dal_giorno"
                    mask="##/##/####"
                    label="Dal giorno">

                    <template v-slot:append>
                        <q-icon name="event" class="cursor-pointer">
                        <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                            <q-date v-model="dal_giorno"
                                mask="DD/MM/YYYY">
                                <div class="row items-center justify-end">
                                    <q-btn v-close-popup label="Close" flat />
                                </div>
                            </q-date>
                        </q-popup-proxy>
                        </q-icon>
                    </template>
                </q-input>
                <br>
                <q-input outlined
                    v-model="al_giorno"
                    mask="##/##/####"
                    label="Al giorno">

                    <template v-slot:append>
                        <q-icon name="event" class="cursor-pointer">
                        <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                            <q-date v-model="al_giorno"
                                mask="DD/MM/YYYY">
                                <div class="row items-center justify-end">
                                    <q-btn v-close-popup label="Close" flat />
                                </div>
                            </q-date>
                        </q-popup-proxy>
                        </q-icon>
                    </template>
                </q-input>
                <br>
                <div align="center">
                    <br>
                        <q-btn
                            color="primary"
                            label="Cerca"
                            :disable="pulsante_rinnovi_disattivato"
                            @click.native="onCercaScadenze"/>
                    <br>
                </div>
            </div>

            <div class="col-12 col-md-5 q-mr-sm justify-center">
                <fieldset>
                    <legend>&nbsp;Selezione rapida&nbsp;</legend>

                    <q-btn flat label="Questa Settimana" icon="mdi-download-network-outline" @click.native="onQuestaSettimana" /><br>
                    <q-btn flat label="Questo Mese"  icon="mdi-download-network-outline" @click.native="onQuestoMese" /><br>
                    <q-btn flat label="Settimana prossima"  icon="mdi-download-network-outline" @click.native = "onSettimanaProssima" /><br>
                    <q-btn flat label="Mese prossimo"  icon="mdi-download-network-outline" @click.native="onMeseProssimo" /><br>
                    <q-btn flat label="Prossimi 15 giorni"  icon="mdi-download-network-outline" @click.native="onProssimi15giorni" /><br>
                    <q-btn flat label="Prossimi 30 giorni"  icon="mdi-download-network-outline" @click.native="onProssimi30giorni" /><br>
                    <q-btn flat label="Terzo mese successivo (per Disdette)"  icon="mdi-download-network-outline" @click.native="onTerzoMeseSuccessivo" />
                </fieldset>
            </div>

        </div>

        <div v-if="elenco_contratti_da_lavorare.length === 0" align="center">
            <h5>Nessuna pratica in scadenza identificata</h5>
        </div>

        <div v-if="elenco_contratti_da_lavorare.length > 0">
            <br>
            <table class="table-bordered">
                <thead>
                    <tr>
                        <th>Fornitore/Unit&agrave; operativa</th>
                        <th>Contraente</th>
                        <th>Compagnia/Tariffa</th>
                        <th>Frazionamento</th>
                        <th>Tacito Rinnovo</th>
                        <th>Disdetta</th>
                        <th>Rinnovata</th>
                        <th>Premio in scadenza</th>
                        <th>Premio rinnovo</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(contratto,index) in elenco_contratti_da_lavorare" v-bind:key="index">
                        <td>{{ contratto.emittente }}<br>{{ contratto.unita_operativa }}<br>{{ contratto.numero }}</td>
                        <td>{{ contratto.nominativo }}</td>
                        <td>{{ contratto.compagnia }}<br>{{ contratto.prodotto_id }}</td>
                        <td>{{ contratto.frazionamento }}</td>
                        <td align="center">
                            <q-checkbox size="lg" v-model="contratto.tacito_rinnovo" val="lg" disable />
                        </td>
                        <td>
                            <q-checkbox size="lg" v-model="contratto.disdettato" val="lg" />
                        </td>
                        <td>
                            <q-checkbox size="lg" v-model="contratto.rinnovato" val="lg" disable />
                        </td>
                        <td align="right">{{ contratto.premioinscadenza }} Euro</td>
                        <td>
                            <q-input type="number"
                                dense outlined
                                v-model="contratto.premio_rinnovo"
                                label="Premio di Rinnovo"
                                :disable="isPremioBloccato(contratto)"
                            >
                                <template v-slot:append>
                                    Euro
                                </template>
                            </q-input>
                        </td>
                    </tr>
                </tbody>
            </table>
            <br><br>
            <div align="center">
                <QQButton
                    label="Registra rinnovi"
                    color="blue-grey"
                    icon="mdi-arrange-send-backward"
                    size="xl"
                    :disable="pulsante_rinnovi_disattivato"
                    @click.native="onRegistraRinnovi"
                />
            </div>
            <br><br><br><br><br><br>
        </div>

    </div>
</template>

<script>
    //import { mapFields} from 'vuex-map-fields';
    import { mapActions } from 'vuex';
    //import { exportFile } from 'quasar';
    import commonLib from "@/libs/commonLib";
    import QQButton from "@/components/QQButton.vue";


    export default {
        name: "RinnovaPraticheDaSede",
        data() {
            return {
                dal_giorno: "",
                al_giorno: "",
                elenco_compagnie: [],
                compagnia: "tutti",
                elenco_contratti_da_lavorare: [],
                disdetta: false,
                is_loading: true,
                pulsante_rinnovi_disattivato: false
            }
        },
        components: {
            QQButton
        },
        computed: {

        },
        methods: {
            ...mapActions({
                fetchContrattiInScadenza: "gestioneRinnovi/fetchContrattiInScadenza",
                fetchRegistraRinnoviDaSede: "gestioneRinnovi/fetchRegistraRinnoviDaSede"
            }),
            isPremioBloccato(contratto) {
                console.log("contratto:",contratto);
                return contratto.rinnovato ||
                       contratto.prodotto_id === "rcp_tecnico" ||
                       contratto.prodotto_id === "rcp_medico" ||
                       contratto.prodotto_id === "rcp_sanitario";
            },
            async onCercaScadenze() {
                if ((!this.dal_giorno === "") || (this.al_giorno === "")) {
                    this.$q.dialog({
                        title: 'Attenzione',
                        message: 'Devi valorizzare i campi DAL GIORNO e AL GIORNO prima di richiedere una ricerca'
                    });
                    return;
                }

                this.is_loading = false;

                //console.log("start");

                this.elenco_contratti_da_lavorare =
                    await this.fetchContrattiInScadenza({DalGiorno: this.dal_giorno, AlGiorno: this.al_giorno});

                // Trasforma il tacito_rinnovo da integer a boolean
                for (var i=0;i<this.elenco_contratti_da_lavorare.length;i++) {
                    var contratto = this.elenco_contratti_da_lavorare[i];
                    if (contratto.tacito_rinnovo === 1)  contratto.tacito_rinnovo = true;
                                                    else contratto.tacito_rinnovo = false;
                }

                this.is_loading = true;

            /*    await this.fetchScadenzeContrattuali({DalGiorno: this.dal_giorno,AlGiorno: this.al_giorno, compagnia: this.compagnia});

                if (this.rows.length === 0) {
                    this.$q.dialog({
                        title: 'Attenzione',
                        message: 'Nessun dato identificato'
                    });
                } */
            },
            onQuestaSettimana() {
                var curr = new Date; // get current date
                console.log("curr:",curr,curr.getDate(),curr.getDay());

                var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
                var last = first + 6; // last day is the first day + 6
                var firstday = new Date(curr.setDate(first)); //.toUTCString();
                var lastday = new Date(curr.setDate(last)); //.toUTCString();

                //console.log("firstDay,lasrDay:",firstday,lastday,curr);

                var giorno_dal = firstday.getDate()+"";
                var mese_dal_giorno = (firstday.getMonth()+1)+"";
                var anno = firstday.getFullYear();

                this.dal_giorno = giorno_dal.padStart(2,'0')+"/"+mese_dal_giorno.padStart(2,'0')+"/"+anno;

                var giorno_al = (lastday.getDate()+"").padStart(2,'0');
                var mese_al_giorno   = ((lastday.getMonth()+1)+"").padStart(2,'0');
                anno   = lastday.getFullYear();

                if ((parseInt(mese_al_giorno) === parseInt(mese_dal_giorno)) && (parseInt(giorno_al) < parseInt(giorno_dal))) {
                    mese_al_giorno = (parseInt(mese_al_giorno) + 1) + "";
                }

                this.al_giorno = giorno_al.padStart(2,'0')+"/"+mese_al_giorno.padStart(2,'0')+"/"+anno;
            },
            onQuestoMese() {
                var date = new Date();
                var firstday = new Date(date.getFullYear(), date.getMonth(), 1);
                var lastday = new Date(date.getFullYear(), date.getMonth() + 1, 0);

                var giorno = firstday.getDate()+"";
                var mese = firstday.getMonth()+1+"";
                var anno = firstday.getFullYear();

                this.dal_giorno = giorno.padStart(2,'0')+"/"+mese.padStart(2,'0')+"/"+anno;

                giorno = (lastday.getDate()+"").padStart(2,'0');
                mese   = (lastday.getMonth()+1+"").padStart(2,'0');
                anno   = lastday.getFullYear();

                this.al_giorno = giorno.padStart(2,'0')+"/"+mese.padStart(2,'0')+"/"+anno;
            },
            onSettimanaProssima() {
                var oggi = new Date();
                var curr = new Date(oggi.getFullYear(), oggi.getMonth(), oggi.getDate()+7);
                var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
                var last = first + 6; // last day is the first day + 6

                var firstday = new Date(curr.setDate(first)); //.toUTCString();
                var lastday = new Date(curr.setDate(last)); //.toUTCString();
                var giorno = firstday.getDate()+"";
                var mese = firstday.getMonth()+1+"";
                var anno = firstday.getFullYear();

                this.dal_giorno = giorno.padStart(2,'0')+"/"+mese.padStart(2,'0')+"/"+anno;

                giorno = (lastday.getDate()+"").padStart(2,'0');
                mese   = (lastday.getMonth()+1+"").padStart(2,'0');
                anno   = lastday.getFullYear();

                this.al_giorno = giorno.padStart(2,'0')+"/"+mese.padStart(2,'0')+"/"+anno;
            },
            onMeseProssimo() {
                var date = new Date();
                var firstday = new Date(date.getFullYear(), date.getMonth() + 1, 1);
                var lastday = new Date(date.getFullYear(), date.getMonth() + 2, 0);
                var giorno = firstday.getDate()+"";
                var mese = firstday.getMonth()+1+"";
                var anno = firstday.getFullYear();

                this.dal_giorno = giorno.padStart(2,'0')+"/"+mese.padStart(2,'0')+"/"+anno;

                giorno = (lastday.getDate()+"").padStart(2,'0');
                mese   = (lastday.getMonth()+1+"").padStart(2,'0');
                anno   = lastday.getFullYear();

                this.al_giorno = giorno.padStart(2,'0')+"/"+mese.padStart(2,'0')+"/"+anno;
            },
            onProssimi15giorni() {
                var date = new Date();
                var firstday = new Date(date.getFullYear(), date.getMonth(), date.getDate());
                var lastday = new Date(date.getFullYear(), date.getMonth() , date.getDate() + 15);
                var giorno = firstday.getDate()+"";
                var mese = firstday.getMonth()+1+"";
                var anno = firstday.getFullYear();

                this.dal_giorno = giorno.padStart(2,'0')+"/"+mese.padStart(2,'0')+"/"+anno;

                giorno = (lastday.getDate()+"").padStart(2,'0');
                mese   = (lastday.getMonth()+1+"").padStart(2,'0');
                anno   = lastday.getFullYear();

                this.al_giorno = giorno.padStart(2,'0')+"/"+mese.padStart(2,'0')+"/"+anno;
            },
            onProssimi30giorni() {
                var date = new Date();
                var firstday = new Date(date.getFullYear(), date.getMonth(), date.getDate());
                var lastday = new Date(date.getFullYear(), date.getMonth() , date.getDate() + 30);
                var giorno = firstday.getDate()+"";
                var mese = firstday.getMonth()+1+"";
                var anno = firstday.getFullYear();

                this.dal_giorno = giorno.padStart(2,'0')+"/"+mese.padStart(2,'0')+"/"+anno;

                giorno = (lastday.getDate()+"").padStart(2,'0');
                mese   = (lastday.getMonth()+1+"").padStart(2,'0');
                anno   = lastday.getFullYear();

                this.al_giorno = giorno.padStart(2,'0')+"/"+mese.padStart(2,'0')+"/"+anno;
            },
            onTerzoMeseSuccessivo() {
                var date = new Date();
                var firstday = new Date(date.getFullYear(), date.getMonth() + 1, 1);
                var lastday = new Date(date.getFullYear(), date.getMonth() + 2, 0);
                var giorno = firstday.getDate()+"";
                var mese = firstday.getMonth()+3+"";
                var anno = firstday.getFullYear();
                this.dal_giorno = giorno.padStart(2,'0')+"/"+mese.padStart(2,'0')+"/"+anno;

                giorno = (lastday.getDate()+"").padStart(2,'0');
                mese   = (lastday.getMonth()+3+"").padStart(2,'0');
                anno   = lastday.getFullYear();

                this.al_giorno = giorno.padStart(2,'0')+"/"+mese.padStart(2,'0')+"/"+anno;
            },
            async onRegistraRinnovi() {
                this.$q.dialog({
                    cancel: {
                        focus: true,
                        flat: false,
                        label: "No",
                        color: "primary"
                    },
                    ok: {
                        color: "secondary",
                        flat: false,
                        label: "Si"
                    },
                    title: 'Attenzione',
                    message: 'Procedo con la registrazione dei rinnovi valorizzati?',
                }).onOk(async () => {
                    this.is_loading = false;
                    this.pulsante_rinnovi_disattivato = true;

                    // Pulisce i campi della lista per togliere valori a null
                    var elenco = this.elenco_contratti_da_lavorare.filter(e => {
                        if (commonLib.isEmpty(e.premio_rinnovo)) e.premio_rinnovo = 0;
                        // Riposiziona il tacito rinnovo a integer da boolean
                        if (e.tacito_rinnovo) e.tacito_rinnovo = 1;
                                        else  e.tacito_rinnovo = 0;
                        return true;
                    });

                    await this.fetchRegistraRinnoviDaSede(elenco);
                    this.is_loading = true;
                    this.pulsante_rinnovi_disattivato = false;

                    // this.$router.push({name : "Pratiche"});
                    this.$q.dialog({
                        title: 'Attenzione',
                        message: 'I premi di rinnovo inseriti sono stati aggiornati.',
                    })
                });
            }
        }
    }


</script>

<style scoped>

    .table {
        width: 100%;
    }
    .table-bordered {
        border-collapse: collapse;
        width: 100%;
    }

    .table-bordered tbody tr td, .table tr th {
        /*border: 1px solid #404040;*/
        border: 1px solid;
        padding: 8px;
    }

    .table-bordered thead tr th {
        padding-top: 12px;
        padding-bottom: 12px;
        padding-left: 8px;
        text-align: left;
        /*color: #404040;
        background-color: #D0D0D0;*/
        border: 1px SOLID #404040;
        background-color: #ffcc99;
        color: #000;
    }

    .table-bordered tFoot tr td {
        padding-top: 12px;
        padding-bottom: 12px;
        padding-left: 8px;
        padding-right: 8px;
        text-align: right;
        border: 1px SOLID;

    }
</style>
