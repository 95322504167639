import { render, staticRenderFns } from "./RinnovaPraticheDaSede.vue?vue&type=template&id=52df0333&scoped=true&"
import script from "./RinnovaPraticheDaSede.vue?vue&type=script&lang=js&"
export * from "./RinnovaPraticheDaSede.vue?vue&type=script&lang=js&"
import style0 from "./RinnovaPraticheDaSede.vue?vue&type=style&index=0&id=52df0333&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52df0333",
  null
  
)

export default component.exports
import QSpinnerHourglass from 'quasar/src/components/spinner/QSpinnerHourglass.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';
import QDate from 'quasar/src/components/date/QDate.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QSpinnerHourglass,QInput,QIcon,QPopupProxy,QDate,QBtn,QCheckbox});qInstall(component, 'directives', {ClosePopup});
